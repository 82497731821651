@import "../styles/const";

.header {
  height: 7%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &_item {
    &:first-child {
      margin-right: calcVerticalVH(8);
    }
  }
}

.logo {

  img {
    max-height: calcVerticalVH(55);
    max-width: calcVerticalVH(190);
    -o-object-fit: contain;
    object-fit: contain;
    @media screen and (orientation:landscape) {
      max-height: calcHorizontalVH(40);
      max-width: calcHorizontalVH(190);
    }
  }
}

.date {
  display: block;
  font-weight: 600;
  font-size: calcVerticalVH($text);
  line-height: 130%;

  @media screen and (orientation:landscape) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calcHorizontalVH($text);
  }

  &_today {
    font-weight: 400;
  }

  &_time {
    @media screen and (orientation:landscape) {
      margin-right: calcHorizontalVH(16);
    }
  }
}