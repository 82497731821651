@import "../styles/const";

.container {
  width: 100%;
  height: 100vh;
  padding: calcVerticalVH(19);
  color: $color-light;
  overflow: hidden;

  @media screen and (orientation:landscape) {
    padding: calcHorizontalVH(19);
  }

  &--available {
    background: $gradient-active;
  }

  &--booked {
    background: $primary;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  background: $background;
  border-radius: calcVerticalVH($border-radius);
  padding: calcVerticalVH(35) calcVerticalVH(40) calcVerticalVH(26);


  @media screen and (orientation:landscape) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  @media screen and (orientation:landscape) {
    padding: calcHorizontalVH(29) calcHorizontalVH(40) calcHorizontalVH(26);
  }
}