@import "../styles/const";

.button {
  display: block;
  width: 100%;
  height: calcVerticalVH(86);
  background: $gradient-active;
  font-size: calcVerticalVH($subtitle);
  line-height: 130%;
  padding: calcVerticalVH(16) calcVerticalVH(36);
  border-radius: calcVerticalVH($border-radius);
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
  margin: calcVerticalVH(20) 0;

  @media screen and (orientation:landscape) {
    width: calcHorizontalVH(317);
    height: calcHorizontalVH(86);
    font-size: calcHorizontalVH($subtitle);
    border-radius: calcHorizontalVH($border-radius);
    padding: calcHorizontalVH(16) calcHorizontalVH(36);
    margin: 0;
  }

  @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
    width: calcVH(317, 600);
    height: calcVH(86, 600);
    font-size: calcVH($subtitle, 600);
    border-radius: calcVH($border-radius, 600);
    padding: calcVH(16, 600) calcVH(36, 600);
  }

  @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
    width: calcVH(317, 800);
    height: calcVH(86, 800);
    font-size: calcVH($subtitle, 800);
    border-radius: calcVH($border-radius, 800);
    padding: calcVH(16, 600) calcVH(36, 800);
  }
}


.gradient-border {
  border: double 4px transparent;
  border-radius: calcVerticalVH($border-radius);
  background-image: -o-linear-gradient(#2A313A, #2A313A),
  -o-linear-gradient(80deg, #21A038 12.54%, #0CCEC2 87.2%);
  background-image: linear-gradient(#2A313A, #2A313A),
  linear-gradient(10deg, #21A038 12.54%, #0CCEC2 87.2%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  -webkit-box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.01);
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.01);
  -webkit-filter: drop-shadow(0px 10px 20px rgba(51, 59, 69, 0.15));
  filter: drop-shadow(0px 10px 20px rgba(51, 59, 69, 0.15));
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);

  @media screen and (orientation:landscape) {
    border-radius: calcHorizontalVH($border-radius);
  }
}