@import "../styles/const";

.slider-container {
  position: relative;
  .range-slider {
    height: calcVerticalVH(8);
    background: #fff;

    @media screen and (orientation:landscape) {
      height: calcHorizontalVH(8);
    }

    &__range {
      background: $gradient-active !important;
    }

    &__thumb {
      width: calcVerticalVH(2) !important;
      height: calcVerticalVH(28) !important;
      background: $primary !important;
      top: auto !important;
      bottom: 0 !important;
      border-radius: 0 !important;
      -webkit-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;

      @media screen and (orientation:landscape) {
        width: calcHorizontalVH(2) !important;
        height: calcHorizontalVH(28) !important;
      }
    }
  }
}

.slider {
  &_track {
    width: 100%;
    height: calcVerticalVH(8);
    border-radius: calcVerticalVH(10);
    background-color: $color-light;
    z-index: 1;

    @media screen and (orientation:landscape) {
      height: calcHorizontalVH(8);
      border-radius: calcHorizontalVH(10);
    }
  }

  &_values {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: calcVerticalVH(14);
    line-height: 130%;
    color: $primary;
    margin-top: calcVerticalVH(20);

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH(14);
      margin-top: calcHorizontalVH(10);
    }
  }

  &_time {
    &-am {
      font-size: calcVerticalVH(14);
      line-height: 130%;
      color: $primary;
      position: absolute;
      left: 0;
      @media screen and (orientation:landscape) {
        font-size: calcHorizontalVH(14);
      }
    }
    &-pm {
      font-size: calcVerticalVH(14);
      line-height: 130%;
      color: $primary;
      position: absolute;
      right: 0;
      @media screen and (orientation:landscape) {
        font-size: calcHorizontalVH(14);
      }
    }
  }

}

.input-time-value {
  width: calcVerticalVH(25);
  text-align: center;
  color: $color-light;
  font-size: calcVerticalVH(14);
  top: calcVerticalVH(-45);
  position: absolute;

  @media screen and (orientation:landscape) {
    width: calcHorizontalVH(25);
    font-size: calcHorizontalVH(14);
    top: calcHorizontalVH(-45);
  }
}
.input-time {
  position: absolute;
  width: 100%;
  bottom: calcVerticalVH(38);
  pointer-events: none;
  outline: none;

  @media screen and (orientation:landscape) {
    bottom: calcHorizontalVH(30);
  }
}

.input-time::-webkit-slider-thumb {
  background-color:  $primary;
  border: none;
  cursor: pointer;
  height: calcVerticalVH(28);
  width: calcVerticalVH(2);
  pointer-events: all;

  @media screen and (orientation:landscape) {
    height: calcHorizontalVH(28);
    width: calcHorizontalVH(2);
  }

}

/* For Firefox browsers */
.input-time::-moz-range-thumb {
  background-color:  $primary;
  border: none;
  cursor: pointer;
  height: calcVerticalVH(28);
  width: calcVerticalVH(2);
  pointer-events: all;

  @media screen and (orientation:landscape) {
    height: calcHorizontalVH(28);
    width: calcHorizontalVH(2);
  }
}

/* Removing the default appearance */
.input-time,
.input-time::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}