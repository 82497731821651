@import "../styles/const";

.buttons-interval {
  margin-bottom: calcVerticalVH(46);
  position: relative;

  @media screen and (orientation:landscape) {
    margin-bottom: 0;
  }
  &_title {
    font-size: calcVerticalVH($text);
    line-height: calcVerticalVH(36);
    text-transform: capitalize;
    margin-bottom: calcVerticalVH(12);

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH($text);
      line-height: calcHorizontalVH(36);
      margin-right: calcHorizontalVH(16);
      margin-bottom: 0;
    }
  }

  &_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    @media screen and (orientation:landscape) {
      justify-content: normal;
    }
  }

  &_item {
    margin-right: calcVerticalVH(8);

    @media screen and (orientation:landscape) {
      margin-right: calcHorizontalVH(16);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_btn {
    padding: calcVerticalVH(8) calcVerticalVH(12);
    -webkit-filter: drop-shadow(0px 10px 20px rgba(30, 140, 117, 0.19));
    filter: drop-shadow(0px 10px 20px rgba(30, 140, 117, 0.19));
    border-radius: calcVerticalVH($border-radius);
    font-size: calcVerticalVH(21);
    line-height: calcVerticalVH(36);

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      padding: calcVH(8, 600) calcVH(16, 600);
      border-radius: calcVH($border-radius, 600);
      font-size: calcVH($text, 600);
      line-height: calcVH(36, 600);
    }

    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      padding: calcVH(8, 900) calcVH(16, 800);
      border-radius: calcVH($border-radius, 800);
      font-size: calcVH($text, 800);
      line-height: calcVH(36, 800);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      padding: calcVH(8, 800) calcVH(16, 800);
      border-radius: calcVH($border-radius, 800);
      font-size: calcVH(22, 800);
      line-height: calcVH(36, 800);
    }
  }

  &_border {
    border: calcVerticalVH(4) solid $primary;
    border-radius: calcVerticalVH($border-radius);

    @media (min-height: 400px) and (max-height: 700px) and (orientation:landscape) {
      border: calcVH(4, 600) solid $primary;
      border-radius: calcVH($border-radius, 600);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      border: calcVH(4, 800) solid $primary;
      border-radius: calcVH($border-radius, 800);
    }
  }
  .error {
    color: $error;
    position: absolute;
  }

  &_disable {
    opacity: 0.5;
    pointer-events: none;
  }
}