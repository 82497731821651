@import '../styles/const';

.fullscreen {

  &__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: $primary;
  }

  &__text {
    font-weight: 600;
    font-size: calcVerticalVH(16);
    line-height: calcVerticalVH(24);
    margin-right: calcVerticalVH(24);

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH(16);
      line-height: calcHorizontalVH(24);
      margin-right: calcHorizontalVH(24);
    }
  }

  &__img {
    width: calcVerticalVH(20);
    height: calcVerticalVH(20);

    @media screen and (orientation:landscape) {
      width: calcHorizontalVH(20);
      height: calcHorizontalVH(20);
    }
  }
  &__version {
    font-weight: 600;
    font-size: calcVerticalVH(14);
    color: $primary;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH(14);
    }

    svg {
      width: calcVerticalVH(14);
      height: calcVerticalVH(14);
      margin-left:  calcVerticalVH(8);
      @media screen and (orientation:landscape) {
        width: calcHorizontalVH(14);
        height: calcHorizontalVH(14);
        margin-left:  calcHorizontalVH(8);
      }
    }
  }
  &_error {
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_text {
      margin-left:  calcVerticalVH(8);
      color: $error;

      @media screen and (orientation:landscape) {
        margin-left:  calcHorizontalVH(8);
      }
    }
  }
}