@import "../../styles/const";

.leftBorderRadius {
  border-radius: 50% 0 0 50%;
}
.rightBorderRadius {
  border-radius: 0 50% 50% 0;
}
.picker-day_buttonContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.picker-day_button {
  height: calcVerticalVH(40);
  width: calcVerticalVH(40);
  padding: 0;
  border-radius: 50%;

  @media (min-width: 450px) {
    height: calcVH(50, 800);
    width: calcVH(50, 800);
  }

  @media (min-height: 200px) and (orientation:landscape) {
    height: calcVH(40, 600);
    width: calcVH(40, 600);
  }

}
.outlined {
  border: 1px solid $primary;
}
.outlinedText {
  background: $gradient-active;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $color-light;
}
.picker-day_buttonText {
  font-size: calcVerticalVH(20);

  @media (min-width: 450px) {
    font-size: calcVH(20, 800)
  }

  @media (min-height: 200px) and (orientation:landscape) {
    font-size: calcVH(20, 600)
  }
}
.filled {
  background: $gradient-active;
}
.disabled {
  color: $primary;
}
.contrast {
  color: #fff;
}
