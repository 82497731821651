@import "../styles/const";

.users {
  display: block;

  @media screen and (orientation:landscape) {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
  }

  &_item {
    &:first-child {
      @media screen and (orientation:landscape) {
        width: 50%;
        margin-right: calcHorizontalVH(16);
      }
    }
    &:nth-child(2) {
      @media screen and (orientation:landscape) {
        width: 50%;
      }
    }
  }

  &_button {

    @media screen and (orientation:landscape) {
      position: absolute;
      bottom: 0;
    }

  }

  &_title {
    margin-bottom: 8px;
  }

  &_filter {
    font-size: calcVerticalVH(22);
    line-height: 130%;
    color: $primary;
    margin-bottom: calcVerticalVH(13);

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH(22);
      margin-bottom: calcHorizontalVH(13);
    }

    input {
      width: 100%;
    }
  }

  &_name {
    font-size: calcVerticalVH($text-small);

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH($text-small);
    }
  }

  input::-webkit-input-placeholder {
    color: $color-light;
  }

  input::-moz-placeholder {
    color: $color-light;
  }

  input:-ms-input-placeholder {
    color: $color-light;
  }

  input::-ms-input-placeholder {
    color: $color-light;
  }

  input::placeholder {
    color: $color-light;
  }

  &_error {
    position: absolute;
    font-size: calcVerticalVH(15);
    color: $error;
    bottom: calcVerticalVH(-26);
    @media screen and (orientation:landscape) {
      bottom: calcHorizontalVH(-26);
      font-size: calcHorizontalVH(15);
    }
  }
}

.user {
  overflow: auto;
  height: calcVerticalVH(570);
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: calcVerticalVH(10);
  padding: calcVerticalVH(13) calcVerticalVH(16);
  margin-top: calcVerticalVH(20);

  @media screen and (orientation:landscape) {
    border-radius: calcHorizontalVH(10);
    padding: calcHorizontalVH(13) calcHorizontalVH(16);
    margin-top: 0;
  }

  &_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: calcVerticalVH(8) 0;
    border-bottom: calcVerticalVH(1) solid $primary;

    @media screen and (orientation:landscape) {
      padding: calcHorizontalVH(8) 0;
      border-bottom: calcHorizontalVH(1) solid $primary;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &_info {
    width: 65%;
  }

  &_name {
    font-size: calcVerticalVH($text-small);
    line-height: 130%;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH($text-small);
    }
  }

  &_position {
    font-size: calcVerticalVH(14);
    line-height: 130%;
    color: $primary;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH(14);
    }
  }

  &_avatar {
    width: 10%;

    &--wrapper {
      width: calcVerticalVH(46);
      height: calcVerticalVH(46);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #C4C4C4;
      border-radius: 50%;

      @media screen and (orientation:landscape) {
        width: calcHorizontalVH(46);
        height: calcHorizontalVH(46);
      }
      @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
        width: calcVH(46, 600);
        height: calcVH(46, 600);
      }
      @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
        width: calcVH(46, 800);
        height: calcVH(46, 800);
      }

      svg {
        width: calcVerticalVH(33);
        height: calcVerticalVH(33);

        @media screen and (orientation:landscape) {
          width: calcHorizontalVH(33);
          height: calcHorizontalVH(33);
        }
        @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
          width: calcVH(33, 600);
          height: calcVH(33, 600);
        }
        @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
          width: calcVH(33, 800);
          height: calcVH(33, 800);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &_check {
    width: 10%;

    svg {
      width: calcVerticalVH(24);
      height: calcVerticalVH(24);

      @media screen and (orientation:landscape) {
        width: calcHorizontalVH(24);
        height: calcHorizontalVH(24);
      }
    }
  }
}