// font-size

$title: 56;
$subtitle: 36;
$text: 24;
$text-small: 18;

// color

$color-light: #fff;
$gradient-active: linear-gradient(39deg, #21A038 0%, #0CCEC2 100%);
$gradient: linear-gradient(39deg, #838585 0%, #CDDBE4 100%);
$primary: #8991A9;
$border: #E4ECEF;
$background: #2A313A;
$box-shadow: 0px 10px 20px 0px rgba(30, 140, 117, 0.19);
$error: #CC0000;


// size elem

$REFERENCE_VERTICAL_HEIGHT: 982;
$REFERENCE_HEIGHT_HORIZONTAL_SCREEN: 500;

@function calcVerticalVH($px) {
  @return calc(($px / $REFERENCE_VERTICAL_HEIGHT * 100) * 1vh);
}

@function calcHorizontalVH($px) {
  @return calc(($px / $REFERENCE_HEIGHT_HORIZONTAL_SCREEN * 100) * 1vh);
}

@function calcVH($px, $height) {
  @return calc(($px / $height * 100) * 1vh);
}


// border

$border-radius: 24;
$border-radius-button: 10;