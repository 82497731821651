@import "../styles/const";

.wrapper-slider {
  position: relative;
  cursor: pointer;
}

.active {
  .slider__left-value,
  .slider__right-value {
    display: block;
  }

  .thumb {
    display: block;
  }

  .slider__track {
    height: calcVerticalVH(8);
    bottom: calcVerticalVH(-8);
    @media screen and (orientation:landscape) {
      height: calcHorizontalVH(8);
      bottom: calcHorizontalVH(-8);
    }
  }
}

.slider {
  position: relative;
}

.slider__control {
  position: relative;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: calcVerticalVH(3);
  height: calcVerticalVH(8);

  @media screen and (orientation:landscape) {
    border-radius: calcHorizontalVH(3);
    height: calcHorizontalVH(8);
  }
}

.slider__track {
  width: 100%;
  background: $gradient-active;
  z-index: 2;
}

.slider__range {
  background: $gradient-active;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  display: none;
  width: calcVerticalVH(25);
  text-align: center;
  color: $color-light;
  font-size: calcVerticalVH(14);
  bottom: calcVerticalVH(calc(28 - 8));

  @media screen and (orientation:landscape) {
    width: calcHorizontalVH(25);
    font-size: calcHorizontalVH(14);
    bottom: calcHorizontalVH(calc(28 - 8));
  }
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  display: none;
  pointer-events: none;
  position: absolute;
  bottom: calcVerticalVH(-8);
  outline: none;

  @media screen and (orientation:landscape) {
    bottom: calcHorizontalVH(-8);
  }
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color:  $primary;
  border: none;
  cursor: pointer;
  height: calcVerticalVH(28);
  width: calcVerticalVH(2);
  pointer-events: all;

  @media screen and (orientation:landscape) {
    height: calcHorizontalVH(28);
    width: calcHorizontalVH(2);
  }

}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color:  $primary;
  border: none;
  cursor: pointer;
  height: calcVerticalVH(28);
  width: calcVerticalVH(2);
  pointer-events: all;

  @media screen and (orientation:landscape) {
    height: calcHorizontalVH(28);
    width: calcHorizontalVH(2);
  }
}