@import '../styles/const';

.spinner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  &_elem {
    width: calcVerticalVH(48);
    height: calcVerticalVH(48);
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;

    @media screen and (orientation:landscape) {
      width: calcHorizontalVH(48);
      height: calcHorizontalVH(48);
    }
  }
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}