@import "../styles/const";

.wrapper {
  display: block;
}

.content_item-booking {
  height: 100%;
  margin-top: calcVerticalVH(13);

  @media screen and (orientation:landscape) {
    height: 90%;
    margin-top: calcHorizontalVH(13);
    position: relative;
  }
}

.button-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: calcVerticalVH($text);
  line-height: 130%;
  margin-bottom: calcVerticalVH(24);
  height: 5%;

  @media screen and (orientation:landscape) {
    font-size: calcHorizontalVH($text);
    margin-bottom: calcHorizontalVH(16);
  }

  &_arrow {
    width: calcVerticalVH(11);
    height: calcVerticalVH(11);
    border: solid;
    border-width: 0 calcVerticalVH(2) calcVerticalVH(2) 0;
    padding: calcVerticalVH(3);
    border-color: $color-light;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-right: calcVerticalVH(8);

    @media screen and (orientation:landscape) {
      width: calcHorizontalVH(11);
      height: calcHorizontalVH(11);
      border-width: 0 calcHorizontalVH(2) calcHorizontalVH(2) 0;
      padding: calcHorizontalVH(3);
      margin-right: calcHorizontalVH(8);
    }
  }
}

.inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @media screen and (orientation:landscape) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  &_item {

    @media screen and (orientation:landscape) {
      margin-right: calcHorizontalVH(16);
    }

    &:first-child {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      margin-bottom: calcVerticalVH(20);

      @media screen and (orientation:landscape) {
        margin-bottom: 0;
      }
    }

    &:nth-child(2) {
      -ms-flex-preferred-size: 47%;
      flex-basis: 47%;
      margin-right: calcVerticalVH(16);
      @media screen and (orientation:landscape) {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-right: calcHorizontalVH(16);
      }
    }

    &:last-child {
      -ms-flex-preferred-size: 47%;
      flex-basis: 47%;
      margin-right: 0;
      @media screen and (orientation:landscape) {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
      }
    }
  }

  &_error {
    .input_wrapper {
      border: calcVerticalVH(1) solid $error !important;

      @media screen and (orientation:landscape) {
        border: calcHorizontalVH(1) solid $error !important;
      }
    }
  }

  .error {
    position: absolute;
    color: $error;
  }
}

.label {
  display: block;
  font-size: calcVerticalVH(16);
  line-height: 130%;
  margin-left: calcVerticalVH(8);
  margin-bottom: calcVerticalVH(5);

  @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
    font-size: calcVH(16, 600);
    margin-bottom: calcVH(5, 600);
    margin-left: calcVH(8, 600);
  }
  @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
    font-size: calcVH(20, 900);
    margin-bottom: calcVH(5, 900);
    margin-left: calcVH(8, 900);
  }
  @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
    font-size: calcVH(20, 800);
    margin-bottom: calcVH(5, 800);
    margin-left: calcVH(8, 800);
  }
}

.form {
  height: 90%;
  &_range {
    display: block;
    margin-top: calcVerticalVH(44);
    @media screen and (orientation:landscape) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-top: calcHorizontalVH(44);
      margin-bottom: calcHorizontalVH(20);
    }
    &-item {
      &:last-child {
        display: none;
        @media screen and (orientation:landscape) {
          display: block;
          width: 49%;
          margin-top: calcHorizontalVH(20);
        }
      }
    }
  }

  &_buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media screen and (orientation:landscape) {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: calcHorizontalVH(10);
    }

    .error {
      &_text {
        color: $error;
        margin-bottom: calcVerticalVH(24);

        @media screen and (orientation:landscape) {
          margin-right: calcHorizontalVH(30);
          margin-bottom: 0;
        }
      }
    }
  }
  &_slider {
    display: none;
    @media screen and (orientation:landscape) {
      display: block;
      margin-left: calcHorizontalVH(16);
      margin-top: calcHorizontalVH(20);
    }
  }
  &_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    @media screen and (orientation:landscape) {
      height: 100%;
    }
  }
}

.input {
  margin-top: calcVerticalVH(24);

  @media screen and (orientation:landscape) {
    margin-top: calcHorizontalVH(24);
  }

  &_wrapper {
    border: calcVerticalVH(1) solid #E4ECEF;
    border-radius: calcVerticalVH($border-radius-button);
    position: relative;
    font-size: calcVerticalVH(22);
    line-height: 130%;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      border: calcVH(1, 600) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 600);
      font-size: calcVH(22, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      border: calcVH(1, 900) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 900);
      font-size: calcVH(22, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      border: calcVH(1, 800) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 800);
      font-size: calcVH(22, 800);
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;

      &:focus-visible {
        border-radius: calcVerticalVH($border-radius-button);

        @media screen and (orientation:landscape) {
          border-radius: calcHorizontalVH($border-radius-button);
        }
      }
    }
  }

  &_date {
    height: calcVerticalVH(60);
    width: 100%;
    padding:0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      height: calcVH(60, 600);
    }

    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      height: calcVH(60, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      height: calcVH(60, 800);
    }
  }

  &_date-picker {
    width: 100%;
    border: calcVerticalVH(1) solid #E4ECEF;
    border-radius: calcVerticalVH($border-radius-button);
    position: relative;
    font-size: calcVerticalVH(22);
    line-height: 130%;
    padding: calcVerticalVH(16);
    height: calcVerticalVH(60);

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      border: calcVH(1, 600) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 600);
      font-size: calcVH(22, 600);
      padding: calcVH(16, 600);
      height: calcVH(60, 600);
    }

    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      border: calcVH(1, 900) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 900);
      font-size: calcVH(22, 900);
      padding: calcVH(16, 900);
      height: calcVH(60, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      border: calcVH(1, 800) solid #E4ECEF;
      border-radius: calcVH($border-radius-button, 800);
      font-size: calcVH(22, 800);
      padding: calcVH(16, 800);
      height: calcVH(60, 800);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      width: 100%;
      height: 100%;
    }
  }

  &_elem {
    height: calcVerticalVH(60);
    padding: calcVerticalVH(16) calcVerticalVH(40) calcVerticalVH(16) calcVerticalVH(16);
    width: 100%;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      height: calcVH(60, 600);
      padding: calcVH(16, 600) calcVH(70, 600) calcVH(16, 600) calcVH(16, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      height: calcVH(60, 900);
      padding: calcVH(16, 900) calcVH(70, 900) calcVH(16, 900) calcVH(16, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      height: calcVH(60, 800);
      padding: calcVH(16, 800) calcVH(70, 800) calcVH(16, 800) calcVH(16, 800);
    }

    &--users {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &_icon {
    position: absolute;
    top: calcVerticalVH(12);
    right: calcVerticalVH(12);
    pointer-events: none;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      top: calcVH(12, 600);
      right: calcVH(12, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      top: calcVH(12, 900);
      right: calcVH(12, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      top: calcVH(12, 800);
      right: calcVH(12, 800);
    }

    svg {
      width: calcVerticalVH(33);
      height: calcVerticalVH(33);
      fill: #8991A9;

      @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
        width: calcVH(33, 600);
        height: calcVH(33, 600);
      }
      @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
        width: calcVH(33, 900);
        height: calcVH(33, 900);
      }
      @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
        width: calcVH(33, 800);
        height: calcVH(33, 800);
      }
    }
  }
}

.item {
  &:first-child {
    width: 80%;
  }

  &:last-child {
    width: 20%;
    text-align: end;
  }
}

.input_select {
  &_time_list {
    height: 500%;
    width: 100%;
    border: calcVerticalVH(1) solid $border;
    border-radius: calcVerticalVH(10);
    position: absolute;
    font-size: calcVerticalVH(22);
    line-height: 130%;
    padding: calcVerticalVH(16) calcVerticalVH(40) calcVerticalVH(16) calcVerticalVH(16);
    background-color: $background;
    overflow: auto;
    z-index: 999;

    @media (orientation:landscape) {
      font-size: calcVH(22, 600);
      padding: calcVH(16, 600) calcVH(40, 600) calcVH(16, 600) calcVH(16,600);
    }


    @media (min-height: 700px) and (orientation:landscape) {
      font-size: calcVH(22, 900);
      padding: calcVH(16, 900) calcVH(40, 900) calcVH(16, 900) calcVH(16,900);
    }
  }
  &_time_item {
    padding-bottom: calcVerticalVH(20);
  }
}