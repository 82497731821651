@import "../styles/const";

.notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100% !important;
  text-align: center;

  &_wrapper {
    border-radius: calcVerticalVH(63) !important;
    @media screen and (orientation:landscape) {
      border-radius: calcHorizontalVH(63) !important;
    }
  }

  &_title {
    font-size: calcVerticalVH($subtitle);
    line-height: 130%;
    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH($subtitle);
    }
  }

  &_text {
    font-size: calcVerticalVH($text);
    line-height: 130%;
    @media screen and (orientation:landscape) {
      font-size: calcHorizontalVH($subtitle);
    }
  }

  &--error {
    .gradient-border {
      border: none;
    }

    .notification_wrapper {
      border: 2px solid $error;
    }
  }

  &_modal {
    padding: calcVerticalVH(40) calcVerticalVH(40);
    @media screen and (orientation:landscape) {
      padding: calcHorizontalVH(40) calcHorizontalVH(80);
    }
  }

  &_icon {
    margin: calcVerticalVH(35) 0;
    @media screen and (orientation:landscape) {
      margin: calcHorizontalVH(35) 0;
    }

    svg {
      width: calcVerticalVH(100);
      height: calcVerticalVH(100);
      @media screen and (orientation:landscape) {
        width: calcHorizontalVH(100);
        height: calcHorizontalVH(100);
      }
    }

  }
}