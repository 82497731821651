.left-to-right {
  &-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s ease;
  }

  &-exit {
    opacity: 0;
    transform: translateX(0);
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: all 1s ease;
  }
}
