@import "../../styles/const";

.date-picker {
  &_wrapper {
    top: calcVerticalVH(60);
    z-index: 10;
    left: 0;
    position: absolute;
    background-color: $background;
    width: 100%;
    padding: calcVerticalVH(16) calcVerticalVH(16) 0 calcVerticalVH(16);
    border: calcVerticalVH(1) solid $border;
    border-radius: calcVerticalVH(8);
    box-shadow: 0 calcVerticalVH(4) calcVerticalVH(20) 0 rgba(228, 236, 239, 0.25);


    @media (min-width: 450px) and (orientation:landscape) {
      top: calcVH(0, 800);
      width: 100%;
    }

    @media (min-height: 200px) and (max-height: 300px) and (orientation:landscape) {
      top: calcVH(-40, 500);
      width: calcVH(450, 500);
      padding: calcVH(24, 600) calcVH(24, 600) 0 calcVH(24, 600);
      border: calcVH(1, 600) solid $border;
      border-radius: calcVH(8, 600);
      box-shadow: 0 calcVH(4, 600) calcVH(20, 600) 0 rgba(228, 236, 239, 0.25);
    }

    @media (min-height: 300px) and (max-height: 500px) and (orientation:landscape) {
      top: calcVH(0, 600);
      width: calcVH(450, 600);
    }

    @media (min-height: 500px) and (orientation:landscape) {
      top: calcVH(45, 600);
      width: calcVH(450, 600);
    }

  }
  &_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: calcVerticalVH(20);
  }
  &_weekDaysContainer {
    display: flex;
    justify-content: space-between;
    margin-top: calcVerticalVH(10);
  }
  &_weekDaysRangeContainer {
    display: flex;
    margin-top: calcVerticalVH(10);
    padding-left: calcVerticalVH(15);
    padding-right: calcVerticalVH(15);
  }
  &_weekDays {
    font-size: calcVerticalVH(16);
    line-height: calcVerticalVH(19);
    font-weight: 600;
    text-transform: uppercase;

    @media (min-height: 200px) and (max-height: 400px) and (orientation:landscape) {
      font-size: calcVH(18, 600)
    }
    @media (min-height: 400px) and (orientation:landscape) {
      font-size: calcVH(18, 800)
    }
  }
  &_daysContainer {
    margin-top: calcVerticalVH(15);
    margin-bottom: calcVerticalVH(20);
    flex-direction: column;
    justify-content: space-between;
  }
  &_arrow {
    padding: calcVerticalVH(8);
    border-radius: calcVerticalVH(8);
    &:hover {
      background: none;
    }
  }
  &_iconContainer {
    display: flex;
  }
  &_hidden {
    display: none !important;
  }
  &_text {
    text-align: center;
  }
}
